<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="rfPaymentForm" @submit.prevent="savePatientPayment" class="custom-form">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" dark :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Commission Payment Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense justify="center">
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="3">P. Date</v-col>
                                                <v-col cols="9">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="payment.date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                autocomplete="off"
                                                                @change="getPayments"
                                                            >
                                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="payment.date"
                                                            @input="menu = false"
                                                            @change="getPayments"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">P. Type</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        :items="['Cash', 'Bank']"
                                                        v-model="payment.type"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="payment.type == 'Bank'" no-gutters class="mb-1">
                                                <v-col cols="3">Bank Acc.</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        @focus="$store.dispatch('bankAccount/getBankAccounts')"
                                                        :loading="$store.getters['bankAccount/loading']"
                                                        :items="$store.getters['bankAccount/bankAccounts']"
                                                        item-text="display_text"
                                                        item-value="id"
                                                        v-model="selectedBankAccount"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Refrall</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        @focus="$store.dispatch('pharmacist/getPharmacists')"
                                                        :loading="$store.getters['pharmacist/loading']"
                                                        :items="$store.getters['pharmacist/pharmacists']"
                                                        item-text="display_text"
                                                        item-value="id"
                                                        v-model="selectedRfBy"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Due</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :value="$store.getters['testReceipt/due'].toFixed(2)"
                                                        readonly
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Amount</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.number="payment.amount"
                                                        min="0"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Description</v-col>
                                                <v-col cols="9">
                                                    <v-textarea
                                                        dense
                                                        outlined
                                                        hide-details
                                                        height="8.3vh"
                                                        v-model="payment.description"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row no-gutters>
                                                         <v-col cols="6" class="pr-1">
                                                            <v-btn @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="30px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="rfPaymentHeaders"
                    :loading="$store.getters['testReceipt/loading']"
                    :items="$store.getters['testReceipt/commissionPayments']"
                    :search="searchTransaction"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Payment List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Transaction"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchTransaction"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editPatientPayment(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deletePayment"></delete-confirm>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'

export default {
    name: 'commission-payment',
    components: {
        DeleteConfirm,
    },
    data: () => ({
        menu: false,
        searchTransaction: '',
        rfPaymentHeaders: [
            { text: 'TRF ID', value: 'code' },
            { text: 'Date', value: 'date' },
            { text: 'Refrall', value: 'pharmacist.name' },
            { text: 'Payment By', value: 'type' },
            { text: 'Amount', value: 'amount' },
            { text: 'Description', value: 'description' },
            { text: 'Action', value: 'action' },
        ],

        selectedBankAccount: null,
        selectedRfBy: null,

        payment: {
            id: null,
            account_id: null,
            ref_by: null,
            date: new Date().toISOString().slice(0, 10),
            type: '',
            amount: 0,
            description: '',
        },
        due: 0,
        deleteId: null,
        waitingForSave: false,
    }),
    watch: {
        async selectedRfBy(val) {
            if (val == null) return
            this.payment.ref_by = val.id
            this.$store.dispatch('testReceipt/getRfDue', {refId: val.id})
        },
        selectedBankAccount(val) {
            if(val == undefined) return;
            this.payment.account_id = val.id
        }
    },
    created() {
        this.getPayments()
    },
    methods: {
        async getPayments() {
            let filter = {
                dateFrom: this.payment.date,
                dateTo: this.payment.date
            }
            await this.$store.dispatch('testReceipt/getCommissionPayments', filter)
        },

        async savePatientPayment() {
            if (!this.$refs.rfPaymentForm.validate()) return

            if(this.selectedRfBy == null) {
                this.$store.dispatch("snackbar/error", "Select refrall");
                return;
            }

            if(this.payment.type == 'Bank' && this.selectedBankAccount == null) {
                this.$store.dispatch("snackbar/error", "Select bank account");
                return;
            }

            if(+this.payment.amount > +this.$store.getters['testReceipt/due']) {
                this.$store.dispatch("snackbar/error", "Payment amount more then due amount");
                return;
            }
            this.waitingForSave = true
            let isSuccess = await this.$store.dispatch('testReceipt/saveCommissionPayment', this.payment)
            if(isSuccess) {
                this.getPayments()
                this.resetForm()
            }
            this.waitingForSave = false
        },

        editPatientPayment(item) {
            this.selectedBankAccount = item.bank_account
            this.selectedRfBy = item.pharmacist
            this.selectedRfBy.display_text = `${item.pharmacist.code} - ${item.pharmacist.name}`
            Object.keys(this.payment).map(k => this.payment[k] = item[k])
        },

        openDeleteDialog(id) {
            this.deleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deletePayment() {
            this.$store.dispatch('testReceipt/deleteCommissionPayment', {id: this.deleteId})
            this.$refs.deleteDialog.dialog = false
            this.deleteId = null
            this.getPayments();
        },
        
        resetForm() {
            this.payment.amount = 0
            this.payment.type = this.payment.description = ''
            this.selectedRfBy = null
            this.selectedBankAccount = null
            this.payment.date = new Date().toISOString().slice(0, 10)
            this.$store.dispatch('testReceipt/getRfDue')

            this.$refs.rfPaymentForm.resetValidation()
        }
    },
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>